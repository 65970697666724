import { useOpus } from "@/core/composables";
import { DEFAULT_PAGE_SIZE, SUPPLIER_SORTING_LIST, SUPPLIER_SORTING_LIST_WITH_RANK } from "@/core/constants";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import searchQueryDocument from "./getSearchResultsQuery.graphql";
import type { GetSearchResultsQueryVariables, Query } from "@/core/api/graphql/types";

export type SearchResultsType = Required<
  Pick<Query, "categories" | "products" | "suppliers" | "pages" | "productSuggestions">
>;

export type GetSearchResultsParamsType = {
  keyword: string;
  filter?: string;

  productSuggestions?: {
    suggestionsSize?: number;
  };

  pages?: {
    page?: number;
    itemsPerPage?: number;
  };

  categories?: {
    page?: number;
    itemsPerPage?: number;
    sort?: string;
    fuzzy?: boolean;
    fuzzyLevel?: number;
    categoriesFilter?: string;
  };

  products?: {
    page?: number;
    itemsPerPage?: number;
    sort?: string;
    fuzzy?: boolean;
    fuzzyLevel?: number;
  };

  suppliers?: {
    itemsPerPage?: number;
    page?: number;
    sort?: string;
    isActive?: boolean;
  };
};

export async function getSearchResults(params: GetSearchResultsParamsType): Promise<SearchResultsType> {
  const { storeId, userId, cultureName, currencyCode } = globals;

  const withSuggestions = !!params.productSuggestions;
  const withPages = !!params.pages;
  const withCategories = !!params.categories;
  const withProducts = !!params.products;
  const withSuppliers = !!params.suppliers;

  const {
    keyword,
    filter,

    productSuggestions: { suggestionsSize: productSuggestionsSize = DEFAULT_PAGE_SIZE } = {},

    pages: { page: staticContentPage = 1, itemsPerPage: staticContentItemsPerPage = DEFAULT_PAGE_SIZE } = {},

    categories: {
      itemsPerPage: categoriesItemsPerPage = DEFAULT_PAGE_SIZE,
      page: categoriesPage = 1,
      sort: categoriesSort,
      fuzzy: categoriesFuzzy,
      fuzzyLevel: categoriesFuzzyLevel,
      categoriesFilter: categoriesFilter,
    } = {},

    products: {
      itemsPerPage: productsItemsPerPage = DEFAULT_PAGE_SIZE,
      page: productsPage = 1,
      sort: productsSort,
      fuzzy: productsFuzzy,
      fuzzyLevel: productsFuzzyLevel,
    } = {},

    // OPUS
    suppliers: {
      itemsPerPage: supplierItemsPerPage = DEFAULT_PAGE_SIZE,
      page: suppliersPage = 1,
      sort: _suppliersSort,
      isActive: suppliersIsActive,
    } = {},
    // !OPUS
  } = params;

  const variables: GetSearchResultsQueryVariables = {
    storeId,
    userId,
    cultureName,
    currencyCode,
    withProducts,
    withCategories,
    withPages,
    withSuggestions,
    query: keyword,
    filter,
    withSuppliers,
  };

  // if (withSuggestions) {
  //   Object.assign(variables, <GetSearchResultsQueryVariables>{
  //     suggestionsSize: productSuggestionsSize,
  //   });
  // }

  // if (withPages) {
  //   Object.assign(variables, <GetSearchResultsQueryVariables>{
  //     pagesFirst: staticContentItemsPerPage,
  //     pagesAfter: String((staticContentPage - 1) * staticContentItemsPerPage),
  //   });
  // }

  if (withCategories) {
    Object.assign(variables, <GetSearchResultsQueryVariables>{
      categoriesSort,
      categoriesFuzzy,
      categoriesFuzzyLevel,
      categoriesFirst: categoriesItemsPerPage,
      categoriesAfter: String((categoriesPage - 1) * categoriesItemsPerPage),
      categoriesFilter,
    });
  }

  if (withProducts) {
    Object.assign(variables, <GetSearchResultsQueryVariables>{
      productsSort,
      productsFuzzy,
      productsFuzzyLevel,
      productsFirst: productsItemsPerPage,
      productsAfter: String((productsPage - 1) * productsItemsPerPage),
    });
  }

  // OPUS
  if (withSuppliers) {
    const { suppliersRankSortingEnabled } = useOpus();
    const defaultSort = suppliersRankSortingEnabled.value
      ? SUPPLIER_SORTING_LIST_WITH_RANK[0].id
      : SUPPLIER_SORTING_LIST[0].id;

    Object.assign(variables, <GetSearchResultsQueryVariables>{
      suppliersSort: _suppliersSort || defaultSort,
      suppliersFirst: supplierItemsPerPage,
      suppliersAfter: String((suppliersPage - 1) * supplierItemsPerPage),
      suppliersIsActive: suppliersIsActive,
    });
  }
  // !OPUS

  const { data } = await graphqlClient.query<SearchResultsType, GetSearchResultsQueryVariables>({
    query: searchQueryDocument,
    variables,
  });

  return Object.assign(
    {
      productSuggestions: {},
      pages: {},
      categories: {},
      products: {},
      suppliers: {},
    },
    data,
  );
}
