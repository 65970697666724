export const PRODUCT_SORTING_LIST = [
  { id: "", name: "Featured" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
  { id: "price-asc;_score-desc;", name: "Price, low to high" },
  { id: "price-desc;_score-desc;", name: "Price, high to low" },
  // { id: "createddate-descending", name: "Date, new to old" },
  // { id: "createddate-ascending", name: "Date, old to new" },
];

export const SUPPLIER_SORTING_LIST = [
  { id: "_score-desc;namelowercase-asc", name: "Featured" },
  { id: "_score-desc;isfavorite-desc", name: "Favorite" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
];

export const SUPPLIER_SEARCH_SORTING_LIST = [
  { id: "_score-desc;namelowercase-asc", name: "Most relevant" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
];

export const SUPPLIER_SORTING_LIST_WITH_RANK = [
  { id: "rank-asc;namelowercase-asc", name: "Featured" },
  { id: "rank-asc;isfavorite-desc", name: "Favorite" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
];

export const SUPPLIER_SEARCH_SORTING_LIST_WITH_RANK = [
  { id: "rank-asc;namelowercase-asc", name: "Most relevant" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
];

export const PRODUCT_OBJECT_TYPE = "CatalogProduct";

// OPUS
export const PRODUCT_SORTING_LIST_WITHOUT_PRICE = [
  { id: "", name: "Featured" },
  { id: "namelowercase-asc", name: "Alphabetically, A-Z" },
  { id: "namelowercase-desc", name: "Alphabetically, Z-A" },
];
// !OPUS
