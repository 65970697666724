import { useOpus } from "@/core/composables";
import { SUPPLIER_SORTING_LIST, SUPPLIER_SORTING_LIST_WITH_RANK } from "@/core/constants";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../../client";
import searchSuppliersLogoQueryDocument from "./searchSuppliersLogoQuery.graphql";
import type { Query, QuerySuppliersArgs, SupplierConnection } from "@/core/api/graphql/types";

export async function searchSuppliersLogo(
  payload: Omit<QuerySuppliersArgs, "storeId" | "userId">,
): Promise<SupplierConnection> {
  const { storeId, userId } = globals;

  const { suppliersRankSortingEnabled } = useOpus();
  const defaultSort = suppliersRankSortingEnabled.value
    ? SUPPLIER_SORTING_LIST_WITH_RANK[0].id
    : SUPPLIER_SORTING_LIST[0].id;

  const { data } = await graphqlClient.query<Required<Pick<Query, "suppliers">>, QuerySuppliersArgs>({
    query: searchSuppliersLogoQueryDocument,
    variables: {
      storeId,
      userId,
      ...payload,
      sort: defaultSort,
    },
  });

  return data.suppliers!;
}
