<template>
  <section class="grow divide-y divide-additional-50 divide-opacity-20 overflow-y-auto">
    <ul v-if="isAuthenticated" class="flex flex-col gap-y-2 px-9 py-6">
      <li>
        <MobileMenuLink :link="menuItem" class="py-1 text-2xl font-normal" @close="$emit('close')">
          {{ menuItem.title }}
        </MobileMenuLink>
      </li>
      <template v-for="item in mobileMainMenuItems" :key="item.title">
        <li v-if="canShowItem(item)">
          <component
            :is="(item.id && customLinkComponents[item.id]) || LinkDefault"
            :item="item"
            @close="$emit('close')"
            @select-item="$emit('selectItem', item)"
          />
        </li>
      </template>
    </ul>

    <!-- OPUS:Guide -->
    <div
      v-if="isAuthenticated"
      :class="user?.passwordExpired ? 'pointer-events-none opacity-25' : ''"
      class="flex flex-col gap-y-2 px-9 py-6 font-normal"
    >
      <template v-for="item in mobileGuideMenuItems" :key="item.title">
        <span
          v-if="item.id === 'contacts'"
          class="flex min-h-9 items-center gap-x-3.5 py-1 text-left text-2xl leading-tight text-[--mobile-menu-link-color]"
          tabindex="0"
          role="button"
          @click="openContactUsModal"
          @keydown.enter="openContactUsModal"
        >
          <svg v-if="item.icon" height="36" width="36" class="shrink-0 text-[color:var(--color-mobile-menu-icon)]">
            <use :href="item.icon" />
          </svg>
          <span class="overflow-wrap-normal word-break-normal line-clamp-3 whitespace-nowrap font-normal">
            {{ item.title }}
          </span>
        </span>
        <MobileMenuLink
          v-else
          :link="item"
          class="py-1 text-2xl font-normal"
          :disabled="user?.passwordExpired"
          @close="$emit('close')"
          @select="$emit('selectItem', item)"
        >
          {{ item.title }}
        </MobileMenuLink>
      </template>
    </div>
    <!-- !OPUS Guide -->

    <div class="flex flex-col gap-y-2 px-9 py-6">
      <template v-if="isAuthenticated">
        <!-- Account link -->
        <ul>
          <!-- OPUS -->
          <!-- <li>
            <MobileMenuLink
              v-if="extendedMobileAccountMenuItem"
              :link="extendedMobileAccountMenuItem"
              class="py-1 text-2xl font-normal"
              @select="$emit('selectItem', extendedMobileAccountMenuItem!)"
            >
              {{ extendedMobileAccountMenuItem.title }}
            </MobileMenuLink>
          </li> -->
          <!-- !OPUS -->

          <!-- Corporate link -->
          <li>
            <MobileMenuLink
              v-if="mobileCorporateMenuItem"
              :link="mobileCorporateMenuItem"
              class="py-1 text-2xl font-normal"
              @select="$emit('selectItem', mobileCorporateMenuItem!)"
            >
              {{ user.contact?.firstName }}
            </MobileMenuLink>
          </li>
        </ul>

        <span
          v-if="organization"
          class="my-2 ml-12 line-clamp-2 text-xl font-normal italic leading-[22px] text-[#D6F0FD]"
        >
          {{ organization?.name }}
        </span>
        <VcButton class="ml-12 w-32 !bg-accent text-13 font-bold" size="sm" @click="signMeOut()">
          {{ $t("shared.layout.header.link_logout") }}
        </VcButton>

        <!-- Account -->
        <div v-if="!!false" class="mb-4 mt-2 flex flex-row gap-4 text-xl">
          <div
            class="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full ring-2 ring-accent-300"
          >
            <VcImage v-if="user.photoUrl" :src="user.photoUrl" :alt="user.contact?.fullName" class="size-12" lazy />
            <VcIcon v-else name="user" />
          </div>

          <div class="flex flex-col leading-tight">
            <div class="flex flex-wrap items-center gap-x-1 text-accent-100">
              <template v-if="operator">
                <span class="line-clamp-3 font-bold [word-break:break-word]">
                  {{ operator.contact?.fullName || operator.userName }}
                </span>

                <span class="text-accent-200">
                  {{ $t("shared.layout.header.top_header.logged_in_as") }}
                </span>
              </template>

              <span class="line-clamp-3 font-bold [word-break:break-word]">
                {{ user.contact?.fullName || user.userName }}
              </span>
            </div>

            <div>
              <button type="button" class="font-bold text-[--mobile-menu-navigation-color]" @click="signMeOut">
                {{ $t("shared.layout.header.link_logout") }}
              </button>
            </div>
          </div>
        </div>

        <!-- Account link -->
        <ul v-if="!!false">
          <li>
            <MobileMenuLink
              v-if="mobileAccountMenuItem"
              :link="mobileAccountMenuItem"
              class="py-1 text-2xl font-bold"
              @select="$emit('selectItem', mobileAccountMenuItem!)"
            >
              {{ mobileAccountMenuItem.title }}
            </MobileMenuLink>
          </li>

          <!-- Corporate link -->
          <li>
            <MobileMenuLink
              v-if="mobileCorporateMenuItem && isCorporateMember"
              :link="mobileCorporateMenuItem"
              class="py-1 text-2xl font-bold"
              @select="$emit('selectItem', mobileCorporateMenuItem!)"
            >
              {{ mobileCorporateMenuItem.title }}
            </MobileMenuLink>
          </li>
        </ul>
      </template>

      <!-- Unauthorized links -->
      <ul v-else class="mb-1">
        <li>
          <MobileMenuLink
            v-for="item in unauthorizedMenuItems"
            :key="item.title"
            :link="item"
            class="py-1.5 text-2xl font-normal"
            @close="$emit('close')"
          >
            {{ item.title }}
          </MobileMenuLink>
        </li>
      </ul>

      <!-- Settings link -->
      <MobileMenuLink
        v-if="supportedCurrencies.length > 1"
        :link="settingsMenuItem"
        class="py-1 text-2xl font-normal"
        @select="$emit('selectItem', settingsMenuItem)"
      >
        {{ $t("shared.layout.header.mobile.settings") }}
      </MobileMenuLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrency, useNavigations } from "@/core/composables";
import { OpusPermissions } from "@/core/enums";
import { useSignMeOut, useUser } from "@/shared/account";
import { useCustomMobileMenuLinkComponents } from "@/shared/layout/composables/useCustomMobileMenuLinkComponents";
import { useModal } from "@/shared/modal";
import { OpContactUsDialog } from "@/shared/opus/common/components";
import type { ExtendedMenuLinkType } from "@/core/types";
import LinkCart from "@/shared/layout/components/header/_internal/mobile-menu/link-components/link-cart.vue";
import LinkCompare from "@/shared/layout/components/header/_internal/mobile-menu/link-components/link-compare.vue";
import LinkDefault from "@/shared/layout/components/header/_internal/mobile-menu/link-components/link-default.vue";
import MobileMenuLink from "@/shared/layout/components/header/_internal/mobile-menu/mobile-menu-link.vue";

interface IProps {
  menuItem: ExtendedMenuLinkType;
}

interface IEmits {
  (event: "close"): void;
  (event: "selectItem", item: ExtendedMenuLinkType): void;
}

defineEmits<IEmits>();
defineProps<IProps>();

const { signMeOut } = useSignMeOut();
const {
  user,
  operator,
  isAuthenticated,
  isMultiOrganization,
  isCorporateMember,
  organization,
  // OPUS
  checkPermissions,
  // !OPUS
} = useUser();
const {
  mobileMainMenuItems,
  mobileCorporateMenuItem,
  mobileAccountMenuItem,
  // OPUS
  mobileGuideMenuItems,
  // !OPUS
} = useNavigations();
const { t } = useI18n();
const { supportedCurrencies } = useCurrency();
const { registerCustomLinkComponent, customLinkComponents } = useCustomMobileMenuLinkComponents();

registerCustomLinkComponent({ id: "cart", component: LinkCart });
registerCustomLinkComponent({ id: "compare", component: LinkCompare });

const unauthorizedMenuItems: ExtendedMenuLinkType[] = [
  // { route: { name: "SignIn" }, title: t("shared.layout.header.link_sign_in") },
  {
    title: t("shared.layout.header.link_login_to_opus"),
    icon: "/static/images/opus/signin-mobile.svg#menu",
    route: { name: "SignIn" },
  },
  {
    title: t("shared.layout.header.link_register_now"),
    icon: "/static/images/opus/icons/user-circle.svg#guide",
    route: { name: "SignUp" },
  },
  // OPUS
  {
    route: { name: "OpAboutUs" },
    icon: "/static/images/opus/icons/case.svg#guide",
    title: t("shared.layout.header.menu.about_us"),
  },
  // !OPUS
];

const settingsMenuItem: ExtendedMenuLinkType = {
  id: "settings",
  icon: "cog",
  children: [{}],
};

// OPUS
const { closeModal, openModal } = useModal();
const userCanApproveOrders = computed(() => checkPermissions(OpusPermissions.CanApproveOrders));

function openContactUsModal() {
  openModal({
    component: OpContactUsDialog,
    props: {
      onResult() {
        closeModal();
      },
    },
  });
}

function canShowItem(item: ExtendedMenuLinkType) {
  if ((item.id === "orders_for_approval_main" || item.id === "orders_for_approval") && !userCanApproveOrders.value) {
    return false;
  } else if (
    (item.id === "supplier_management_main" || item.id === "company_info" || item.id === "company_members") &&
    !isCorporateMember.value
  ) {
    return false;
  } else {
    return true;
  }
}
// !OPUS
</script>
